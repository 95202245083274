@media (min-width: 1023px) {
    /* tamanho do scroll */
    ::-webkit-scrollbar {
        width: 7px;
    }

    /* border radius do container do scroll/barra completa */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* border radius e background do scroll/posição atual */
    ::-webkit-scrollbar-thumb {
        background: lightgrey;
        border-radius: 10px;
    }
}