@import '../../../../../utils/styles/desktop-scroll.scss';

vip-listagem-carrinho-desktop {
  .container {
    @apply shadow;
    padding: 1.25rem;
    border-radius: 0.625rem;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 13fr repeat(2, 6fr) 4fr repeat(2, 5fr);
    gap: 0.5rem;
    margin-top: 0.625rem;
  }

  .grid-item {
    padding: 0.313rem;
    text-align: left;
    color: var(--vip-color-secondary-default-main);
    font-weight: bold;
  }

  .header {
    background-color: var(--vip-color-complementary-main);
    color: var(--vip-color-primary-main);
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    grid-column: span 10;
    margin-bottom: 0.625rem;
  }

  .text-error {
    font-family: Roboto !important;
    margin-left: 0.625rem;
    font-weight: 500;
  }

  .scroll {
    max-height: 37.5rem;
    padding-right: 0.938rem;
    overflow-y: auto;
  }
  .title {
    color: var(--vip-color-first-default-main);
    font-weight: bold;
  }

  .icon {
    font-size: 1rem;
    align-items: center;
  }

  swiper {
    --swiper-navigation-size: 0.938rem;
  }

  .swiper {
    position: unset;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }

  .swiper-slide {
    width: fit-content;
  }
  .scrollable-section {
    overflow: auto;
  }

  @media (max-width: 1200px) {
    .grid-container {
      display: grid;
      grid-template-columns: 13fr repeat(2, 4fr) 3fr repeat(2, 4fr);
      gap: 0.5rem;
      margin-top: 0.625rem;
    }
  }

  .desktop-cart-max-height {
    /* 75px: altura da barra de pesquisa */
    /* 75px: altura do header do carrinho */
    /* 12vh: margem inferior */
    max-height: calc(100vh - 75px - 60px - 12vh);
  }

  .vip-divider-swiper {
    @apply w-[97%] absolute z-0;
    height: 1px;
    border-top: 1px solid var(--vip-color-fourth-default-main);
    bottom: 0.019rem;
    left: 0.938rem;
  }
}
